<template>
  <div class="container">
    <div id="contentPage">
      <h1>{{ page.title }}</h1>
      <div id="search"></div>
    </div>
  </div>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";
  import eventBus from "../eventBus";

  export default {
    mixins: [
      LangMixin
    ],

    props: {
      page: {
        type: Object,
        required: true
      }
    },

    mounted() {
      this.initCse();

      eventBus.$on('langChange',  ({ lang, history }) => {
        if (! history) {
          const slug = lang === 'lat' ? 'rezultati-pretrage' : 'резултати-претраге'
          this.$router.push(`/${slug}?q=${this.$route.query.q}`)
        }
      })
    },

    watch: {
      '$route.query.q'() {
        this.initCse();
      }
    },

    methods: {
      initCse() {
        const searchContainer = document.querySelector('#search');

        if (document.querySelector('#cse')) {
          searchContainer.innerHTML = '';
        }

        const resultContainer = document.createElement('div');
        resultContainer.classList.add('gcse-searchresults-only');
        searchContainer.appendChild(resultContainer)

        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://cse.google.com/cse.js?cx=62cb09686187e715f';
        script.id = 'cse';
        searchContainer.appendChild(script);
      }
    }
  }
</script>