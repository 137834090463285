<template>
  <div class="container">
    <div id="contentPage">
      <h1>
        {{ page[lang].title }}
      </h1>

      <div id="contentTopDiv">
        <main-article :article="featuredItem"/>
        <article-medium-box
            v-for="(mainSideArticle, key) in mainItems"
            :key="key"
            :article="mainSideArticle"
            :class="{ 'last' : (key + 1) % 2 === 0}"
        />
      </div>

      <div class="NewsDiv20">
        <article-medium-box v-for="(article, key) in sideItems" :key="key" :article="article"/>
        <infinite-loading @infinite="requestArticles" ref="InfiniteLoading">
          <div slot="no-more"></div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
  import ArticleMediumBox from "../components/ArticleMediumBox";
  import InfiniteLoading from 'vue-infinite-loading';
  import LangMixin from "../mixins/LangMixin";
  import ArticleCategoryListMixin from "../mixins/ArticleCategoryListMixin";
  import eventBus from "../eventBus";
  import MainArticle from "../partials/home/MainArticle";
  import CollectionMixin from "../mixins/CollectionMixin";

  export default {
    components: {
      MainArticle,
      ArticleMediumBox,
      InfiniteLoading
    },

    mixins: [
        LangMixin,
        ArticleCategoryListMixin,
        CollectionMixin
    ],

    props: {
      page: {
        type: Object,
        required: true
      }
    },

    mounted() {
      this.initData()

      eventBus.$on('langChange',  ({ lang, history }) => {
        if (! history) {
          this.$router.push(
              this.findArticleCategory(this.$route.params.slug)[lang].slug
          )
        }

        this.initData()
      })
    },

    watch: {
      page() {
        this.$refs.InfiniteLoading.stateChanger.reset(); 
        this.initData();
        //this.requestArticles();
      },
    },

    methods: {
      setSideArticleData({start, length, nextpg, data}) {
        this.pagination.start = start;
        this.pagination.length = length;
        this.pagination.nextPage = nextpg;
        this.sideItems.push(...data);
      },

      requestArticles($state) {
        // if(this.pagination.nextPage===-1){
        //    this.loadMore = false;
        //    $state.complete();
        //   return;
        // }
        const uri = `getposts?cat=${this.page[this.lang].api_param}&lng=${this.lang}&sr=${this.pagination.nextPage}`;
        this.requestData(uri, data => {
          if (data.length) {
            if (!this.pagination.nextPage) {
              this.featuredItem = data.data[0];
              this.mainItems.push(...data.data.slice(1, 5));
              data.data = data.data.slice(5);
            }

            this.setSideArticleData(data);
            this.loadMore = true;
          }else {
            this.loadMore = false;
          }
        }, $state)
      },
    }
  }
</script>
