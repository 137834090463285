<template>
  <div class="container-fluid">
    <div class="container" :class="{ halfCont: !page.PageTemplate }">
      <div id="contentPage">
        <h3 v-if="page.PageType == 'post'">
          {{ setLocalDate(page.DTPublished) }}
        </h3>
        <h1>
          {{ page.Title }}
        </h1>
        <img
          v-if="page.MainImage"
          :alt="page.Title"
          :src="resolveImg(page.MainImage)"
        />
        <div class="imageInfo" v-if="page.ImageInfo">{{ page.ImageInfo }}</div>
        <div v-html="page.Content" class="mt-3"></div>
      </div>

      <article-sidebar v-if="!page.PageTemplate" />
    </div>
  </div>
</template>

<script>
import eventBus from "../eventBus";
import LangMixin from "../mixins/LangMixin";
import ArticleSidebar from "../components/ArticleSidebar";
import $ from "jquery";
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";
import ArticleMixin from "../mixins/ArticleMixin";

export default {
  mixins: [LangMixin, ArticleMixin],
  components: {
    ArticleSidebar,
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.parseGalleryTag();
    eventBus.$on("langChange", () => this.$router.push({ name: "Home" }));
  },

  methods: {
    setLocalDate(dtStr) {
      let d = new Date(dtStr);
      d.setTime(d.getTime() + new Date().getTimezoneOffset() * 60 * 1000);
      return d.toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    activateGallery() {
      $(".gallery").magnificPopup({
        type: "image",
        gallery: {
          enabled: true,
        },
      });
    },

    requestGallery(id) {
      return fetch(`${this.$bhrt.api_uri}/gallery?id=${id}`).then((response) =>
        response.json()
      );
    },

    renderGallery(data) {
      const container = document.createElement("div");
      container.classList.add("galleryContainer");
      data.images.forEach((image) =>
        container.appendChild(this.createGalleryItem(image))
      );

      return container;
    },

    createGalleryItem(src) {
      const link = this.createGalleryItemLink(src);
      const image = this.createGalleryItemImage(src);
      const divBorder=document.createElement("div");
      divBorder.classList.add("galleryBorder");

      link.appendChild(image);
      link.appendChild(divBorder);

      return link;
    },

    createGalleryItemLink(src) {
      const link = document.createElement("a");
      link.setAttribute("href", src);
      link.setAttribute("class", "gallery");

      return link;
    },

    createGalleryItemImage(src) {
      const image = document.createElement("img");
      image.setAttribute("src", src);
      image.width = 200;
      image.height = 200;

      return image;
    },

    parseGalleryTag() {
      document.querySelectorAll("gallery").forEach((gallery) => {
        gallery.innerHTML = "";
        this.requestGallery(gallery.getAttribute("data-id")).then((data) => {
          if (data.error) return;
          gallery.parentNode.insertBefore(
            this.renderGallery(data[0]),
            gallery.nextSibling
          );
          this.activateGallery();
        });
      });
    },
  },
};
</script>
