<template>
  <div class="container">
    <div id="contentPage">
      <h1>
        {{ page.title }}
      </h1>

      <div id="contentTopDiv">
        <featured-box
            v-if="Object.keys(featuredItem).length"
            :url="resolveUrl(featuredItem)"
            :img="resolveBgmg(featuredItem, 'high')"
            :disabled="['tag']"
        >
          <template v-slot:title>
            {{ resolveTitle(featuredItem) }}
          </template>

          <template v-slot:description>
            {{ resolveDescription(featuredItem) }}
          </template>
        </featured-box>

        <medium-box
            v-for="(mainItem, key) in mainItems"
            :key="key"
            :url="resolveUrl(mainItem)"
            :img="resolveBgmg(mainItem)"
            :disabled="['tag']"
            :class="{ 'last' : (key + 1) % 2 === 0}"
        >
          <template v-slot:title>
            {{ resolveTitle(mainItem) }}
          </template>

          <template v-slot:description>
            {{ resolveDescription(mainItem) }}
          </template>
        </medium-box>
      </div>

      <div class="NewsDiv20">
        <medium-box
            v-for="(sideItem, key) in sideItems"
            :key="key"
            :url="resolveUrl(sideItem)"
            :img="resolveBgmg(sideItem)"
            :disabled="['tag']"
        >
          <template v-slot:title>
            {{ resolveTitle(sideItem) }}
          </template>

          <template v-slot:description>
            {{ resolveDescription(sideItem) }}
          </template>
        </medium-box>
      </div>
    </div>
  </div>
</template>

<script>
  import LangMixin from "../mixins/LangMixin";
  import FeaturedBox from "../components/FeaturedBox";
  import MediumBox from "../components/MediumBox";
  import CollectionMixin from "../mixins/CollectionMixin";
  import eventBus from "../eventBus";

  export default {
    components: {
      FeaturedBox,
      MediumBox
    },

    mixins: [
        LangMixin,
        CollectionMixin
    ],

    props: {
      page: {
        type: Object,
        required: true
      }
    },

    mounted() {
      this.initData();
      this.requestPlaylistCollection();

      eventBus.$on('langChange',  ({ lang, history }) => {
        if (! history) {
          this.$router.push(
              lang === 'lat' ? 'emisije-tv' : 'емисије-тв'
          )
        }
      })
    },

    watch: {
      page() {
        this.initData();
        this.requestPlaylistCollection();
      },
    },

    methods: {
      resolveUrl(item) {
        const slug = this.lang === 'lat' ? item.url : item.urlc
        return `${this.$route.params.slug}/${slug}`
      },

      resolveBgmg(item, size = 'medium') {
        return { backgroundImage : `url('${item.snippet.thumbnails[size].url}')` }
      },

      resolveTitle(item) {
        return item.snippet.title;
      },

      resolveDescription(item) {
        return item.snippet.description;
      },

      setSideArticleData({items}) {
        this.sideItems.push(...items);
      },

      requestPlaylistCollection() {
        this.requestData('plists', data => {
          if (data.items.length) {
            this.featuredItem = data.items[0];
            this.mainItems.push(...data.items.slice(1, 5));
            data.items = data.items.slice(5);

            this.setSideArticleData(data);
            this.loadMore = true;
          }else {
            this.loadMore = false;
          }
        })
      },
    }
  }
</script>