<template>
  <component :is="view" :page="page"></component>
</template>

<script>
  import ArticleCategory from "./ArticleCategory";
  import Article from "./Article";
  import VideoPlaylistCollection from "./VideoPlaylistCollection";
  import AudioPlaylistCollection from "./AudioPlaylistCollection";
  import Search from "./Search";
  import LangMixin from "../mixins/LangMixin";
  import ArticleCategoryListMixin from "../mixins/ArticleCategoryListMixin";

  export default {
    components: {
      ArticleCategory,
      Article,
      VideoPlaylistCollection,
      AudioPlaylistCollection,
      Search
    },

    mixins: [
        LangMixin,
        ArticleCategoryListMixin
    ],

    data() {
      return {
        page: {},
        view: '',
      }
    },

    watch: {
      $route(to) {
        this.emitLangChangeFromUrl(true)
        this.findPage(to.params.slug)
      }
    },

    mounted() {
      this.emitLangChangeFromUrl()
      this.findPage(this.$route.params.slug)
    },

    methods: {
      requestData(path) {
        return fetch(`${this.$bhrt.api_uri}/${path}`)
            .then(response => response.json())
            .catch(error => console.log(error));
      },

      findPage(slug) {
        this.$emit('pageLoading')

        const category = this.findArticleCategory(slug);
        const media = this.findMedia(slug);
        const search = this.findSearch(slug);

        if (category) {
          this.page = category;
          this.view = 'ArticleCategory'
          this.$emit('pageLoaded');
        }

        else if (media) {
          this.page = media.page;
          this.view = media.view;
          this.$emit('pageLoaded');
        }

        else if (search) {
          this.page = search;
          this.view = 'Search';
          this.$emit('pageLoaded');
        }

        else {
          this.requestArticle(slug);
        }
      },

      requestArticle(slug) {
        this.requestData(slug)
          .then(response => {
            if (response && response.length) {
              this.page = response[0]
              this.view = 'Article'
              this.$emit('pageLoaded');
            }else {
              this.$router.push({ name: 'not_found'})
            }
          })
      },

      findMedia(slug) {
        const viodeSlugList = [
            'emisije-tv',
            'емисије-тв'
        ]

        const audioSlugList = [
            'emisije-radio',
            'емисије-радио'
        ]


        if (viodeSlugList.includes(slug)) {
          return {
            page : {
              title : this.lang === 'lat' ? 'Tv emisije' : 'TV емисије'
            },
            view : 'VideoPlaylistCollection'
          };
        }

        if (audioSlugList.includes(slug)) {
          return {
            page : {
              title : this.lang === 'lat' ? 'Radio emisije' : 'Радио емисије'
            },
            view : 'AudioPlaylistCollection'
          }
        }

        return null;
      },

      findSearch(slug) {
        if (! ['rezultati-pretrage', 'резултати-претраге'].includes(slug)) return;

        return {
          title: this.lang  === 'lat' ? 'Rezultati pretrage' : 'Резултати претраге'
        }
      }
    }
  }
</script>