<template>
  <div class="container">
    <div id="contentPage">
      <h1>
        {{ page.title }}
      </h1>

      <div id="contentTopDiv">
        <featured-box
            v-if="Object.keys(featuredItem).length && Object.keys(featuredItem[lang]).length"
            :url="resolveUrl(featuredItem[lang])"
            :img="resolveBgmg(featuredItem[lang])"
            :disabled="['description']"
        >
          <template v-slot:tag>
            {{ resolveCategory(featuredItem[lang]) }}
          </template>

          <template v-slot:title>
            {{ resolveTitle(featuredItem[lang]) }}
          </template>
        </featured-box>

        <medium-box
            v-for="(mainItem, key) in mainItems[lang]"
            :key="key"
            :url="resolveUrl(mainItem)"
            :img="resolveBgmg(mainItem)"
            :disabled="['description']"
            :class="{ 'last' : (key + 1) % 2 === 0}"
        >
          <template v-slot:tag>
            {{ resolveCategory(mainItem) }}
          </template>

          <template v-slot:title>
            {{ resolveTitle(mainItem) }}
          </template>
        </medium-box>
      </div>

      <div class="NewsDiv20">
        <medium-box
            v-for="(sideItem, key) in sideItems[lang]"
            :key="key"
            :url="resolveUrl(sideItem)"
            :img="resolveBgmg(sideItem)"
            :disabled="['description']"
        >
          <template v-slot:tag>
            {{ resolveCategory(sideItem) }}
          </template>

          <template v-slot:title>
            {{ resolveTitle(sideItem) }}
          </template>
        </medium-box>
      </div>
    </div>
  </div>
</template>

<script>
import LangMixin from "../mixins/LangMixin";
import FeaturedBox from "../components/FeaturedBox";
import MediumBox from "../components/MediumBox";
import CollectionMixin from "../mixins/CollectionMixin";
import eventBus from "../eventBus";

export default {
  components: {
    FeaturedBox,
    MediumBox
  },

  mixins: [
    LangMixin,
    CollectionMixin
  ],

  props: {
    page: {
      type: Object,
      required: true
    }
  },

  mounted() {
    this.initData();
    this.setTranslatedData();
    this.requestPlaylistCollection();

    eventBus.$on('langChange',  ({ lang, history }) => {
      if (! history) {
        this.$router.push(
            lang === 'lat' ? 'emisije-radio' : 'емисије-радио'
        )
      }
    })
  },

  watch: {
    page() {
      this.initData();
      this.setTranslatedData();
      this.requestPlaylistCollection();
    },
  },

  methods: {
    resolveUrl(item) {
      return item.url;
    },

    resolveBgmg(item) {
      return { backgroundImage : `url('${item.image}')` }
    },

    resolveTitle(item) {
      return item.title;
    },

    resolveCategory(item) {
      return item.cat;
    },

    setSideArticleData({lat, cir}) {
      this.sideItems.lat.push(...lat);
      this.sideItems.cir.push(...cir);
    },

    requestPlaylistCollection() {
      this.requestData('relists', data => {
        if (data.result.lat.length && data.result.cir.length) {
          this.featuredItem = this.translateData(data.result.lat[0], data.result.cir[0]);
          this.mainItems.lat.push(...data.result.lat.slice(1, 5));
          this.mainItems.cir.push(...data.result.cir.slice(1, 5));
          data.result.lat = data.result.lat.slice(5);
          data.result.cir = data.result.cir.slice(5);

          this.setSideArticleData(data.result);
          this.loadMore = true;
        }else {
          this.loadMore = false;
        }
      })
    },

    setTranslatedData() {
      this.featuredItem = this.translateData('', '');
      this.mainItems = this.translateData([], []);
      this.sideItems = this.translateData([], []);
    },

    translateData(lat, cir) {
      return {
        lat : lat,
        cir : cir
      }
    }
  }
}
</script>